import { useState, createContext } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ originalAppTitle, originalAppSubtitle, originalAppID, originalAppLang, children }) => {

    const [appTitle, setAppTitle] = useState(originalAppTitle);
    const [appSubtitle, setAppSubtitle] = useState(originalAppSubtitle);
    const [appID, setAppID] = useState(originalAppID)
    const [appLang, setAppLang] = useState(originalAppLang);

 
    return (
        <AppContext.Provider value={{ appTitle, setAppTitle, appSubtitle, setAppSubtitle, appID, setAppID, appLang, setAppLang }}>
            {children}
        </AppContext.Provider>
    )
}