import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next'; 
import Color from 'color';

import Cookies from 'universal-cookie';

import { AppContext } from '../contexts/AppProvider.jsx';
import { ThemeContext } from '../contexts/ThemeProvider.jsx';
import { setColors } from '../libs/utils';
import { fetchGallery } from '../libs/call';

function AppWrapper({children}) {

    const { theme, themeColor, themeIconClass } = useContext(ThemeContext);
    const { appLang, appID, setAppTitle, setAppSubtitle } = useContext(AppContext);

    useEffect(() => {

        fetchGallery(appID).then((response) => {
            // setGallery(response.result);
            setAppTitle(response.result.title);
            setAppSubtitle(response.result.subtitle);
            // setAlbums(response.result.albums);
          });

    },[]);

    const { t, i18n } = useTranslation()

    useEffect(() => {
        if(theme === "dark") {
            document.body.classList.remove('theme-light');
            document.body.classList.add('theme-dark');
            localStorage.setItem('theme','dark');
        }
        else {
            document.body.classList.remove('theme-dark');
            document.body.classList.add('theme-light');
            localStorage.setItem('theme','light');
        }
    },[theme]);

    useEffect(() => {
        const color = Color(themeColor);
        localStorage.setItem('themeColor',themeColor);
        const systemColor = color.hex();
        let systemColorRGB = color.rgb();
        systemColorRGB = systemColorRGB.color.join(", ")
        const systemColorDarken1 = color.darken(0.1).hex();
        const systemColorDarken2 = color.darken(0.2).hex();
        const systemColorDarken3 = color.darken(0).hex();
        localStorage.setItem("systemColor", systemColor);
        localStorage.setItem("systemColorRGB", systemColorRGB);
        localStorage.setItem("systemColorDarken1", systemColorDarken1);
        localStorage.setItem("systemColorDarken2", systemColorDarken2);
        localStorage.setItem("systemColorDarken3", systemColorDarken3);
        setColors();
    },[themeColor]);

    useEffect(() => {
        localStorage.setItem('themeIconClass',themeIconClass);
    },[themeIconClass]);

    useEffect(() => {
        localStorage.setItem('appLang',appLang);
        i18n.changeLanguage(appLang);
    },[appLang]);

    return(
        <div className={`App-wrapper theme-${theme}`}>
            {children}
        </div>
    );
}

export default AppWrapper;