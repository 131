import React, { useContext } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';

import { CirclePicker } from 'react-color';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { ThemeContext } from '../contexts/ThemeProvider.jsx';
import { AppContext } from '../contexts/AppProvider.jsx';

function BasicOffcanvas({show,handleCloseMenu}) {

  const { toggleTheme, theme, setThemeColor, themeColor, setThemeIconClass, themeIconClass } = useContext(ThemeContext);
  const { appLang, setAppLang } = useContext(AppContext);
  return (
    <Offcanvas show={show} onHide={handleCloseMenu}>
        <Offcanvas.Header closeButton closeVariant={theme === "dark" ? "white" : ""}>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* <span onClick={handleToggleDarkMode}>Activer/Désactiver le dark mode</span> */}
          <Form.Check 
            type="switch"
            id="custom-switch"
            label="Activer le mode Sombre"
            onChange={toggleTheme}
            checked={theme === "dark"}
          />
          <Form.Label htmlFor="theme-color" className='mt-3'>Couleur du thème</Form.Label>
          <CirclePicker
            color={themeColor}
            onChange={(color) => {
              setThemeColor(color.hex);
            }}
          />
          <Form.Label htmlFor="theme-color" className='mt-3'>Type d'icône</Form.Label>
          <Form.Select 
            aria-label="Type d'icône"
            value={themeIconClass}
            onChange={(event) => {
              setThemeIconClass(event.target.value);
            }}
          >
              {/* <option>Choisissez le type d'icône</option> */}
              <option value="solid">Solid</option>
              <option value="regular">Regular</option>
              <option value="light">Light</option>
              <option value="thin">Thin</option>
              <option value="duotone">Duotone</option>
          </Form.Select>
          <Form.Label htmlFor="theme-color" className='mt-3'>Langue</Form.Label>
          <Form.Select 
            aria-label="Langue"
            value={appLang}
            onChange={(event) => {
              setAppLang(event.target.value);
            }}
          >
              {/* <option>Choisissez la langue</option> */}
              <option value="fr">🇫🇷 Français</option>
              <option value="en">🇬🇧 English</option>
              {/* <option value="es">🇪🇸 Español</option>
              <option value="it">🇮🇹 Italiano</option>
              <option value="hi">🇮🇳 हिन्दी</option> */}
          </Form.Select>
        </Offcanvas.Body>
      </Offcanvas>
  );
}

export default BasicOffcanvas;