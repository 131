export const setColors = () => {
	const systemColor = localStorage.getItem("systemColor");
	const systemColorRGB = localStorage.getItem("systemColorRGB");
	const systemColorDarken1 = localStorage.getItem("systemColorDarken1");
	const systemColorDarken2 = localStorage.getItem("systemColorDarken2");
	const systemColorDarken3 = localStorage.getItem("systemColorDarken3");
	document.querySelector(':root').style.setProperty('--theme-color', systemColor);
	document.querySelector(':root').style.setProperty('--theme-color-rgb', systemColorRGB);
	document.querySelector(':root').style.setProperty('--bs-primary-rgb', systemColorRGB);
	document.querySelector(':root').style.setProperty('--theme-color-darken1', systemColorDarken1);
	document.querySelector(':root').style.setProperty('--theme-color-darken2', systemColorDarken2);
	document.querySelector(':root').style.setProperty('--theme-color-darken3', systemColorDarken3);
	if(document.querySelector('.btn-primary')) {
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-primary', systemColor); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-bg', systemColor); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-color', 'white'); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-border-color', systemColor); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-hover-bg', systemColorDarken1); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-hover-border-color', systemColorDarken1); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-active-bg', systemColorDarken2); })
		document.querySelectorAll('.btn-primary').forEach((e) => { e.style.setProperty('--bs-btn-active-border-color', systemColorDarken2); })
	}
	if(document.querySelector('.btn-outline-primary')) {
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-primary', systemColor); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-color', systemColor); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-border-color', systemColor); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-hover-bg', systemColorDarken1); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-hover-border-color', systemColorDarken1); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-active-bg', systemColorDarken2); })
		document.querySelectorAll('.btn-outline-primary').forEach((e) => { e.style.setProperty('--bs-btn-active-border-color', systemColorDarken2); })
	}
	if(document.querySelector('.nav-link')) {
		document.querySelectorAll('.nav-link').forEach((e) => { e.style.setProperty('--bs-nav-link-color', systemColor); })
		document.querySelectorAll('.nav-link').forEach((e) => { e.style.setProperty('--bs-nav-pills-link-active-bg', systemColor); })
		document.querySelectorAll('.nav-link').forEach((e) => { e.style.setProperty('--bs-nav-link-hover-color', systemColorDarken1); })
	}
		
  	return true;
};