const API_URL = 'api.guru-worlds.com';
const CLOUD_URL = 'cloud.guru-worlds.com';

export const checkApplication = async () => {
  const API_CALL_URL = API_URL+'/authority';
  var formData = new FormData();
  formData.append('action', 'checkApplication');
  const options = { 
    method: 'POST',
    body: formData
  };
  const response = await fetch('https://'+API_CALL_URL,options);
  const data = await response.json();
  console.info("Application data",data.result);
  return data;
};

export const askPassport = async () => {
  if(localStorage.getItem("passport_photos")) {
    return localStorage.getItem("passport_photos");
  }
  const API_CALL_URL = API_URL+'/authority';
  var formData = new FormData();
  formData.append('action', 'askPassport');
  const options = { 
    method: 'POST',
    body: formData
  };
  const data = await fetch('https://'+API_CALL_URL,options);
  const passportData = await data.json();
  const passport = passportData.result;
  localStorage.setItem("passport_photos", passport);
  return passport;
};

export const fetchGallery = async (galleryID) => {
  const API_CALL_URL = API_URL+'/gallery/'+galleryID;
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://'+API_CALL_URL,options);
  const data = await response.json();
  console.info("Gallery data",data.result);
  return data;
};


export const fetchLocationObjects = async (location) => {
  const API_CALL_URL = API_URL+'/inventory/location/'+location;
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://'+API_CALL_URL,options);
  const data = await response.json();
  return data;
};

export const fetchCategoryObjects = async (category) => {
  const API_CALL_URL = API_URL+'/inventory/category/'+category;
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://'+API_CALL_URL,options);
  const data = await response.json();
  return data;
};

export const fetchTask = async (taskID) => {
  const API_CALL_URL = API_URL+'/task/'+taskID;
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://'+API_CALL_URL,options);
  const data = await response.json();
  console.info("Task data",data.result);
  return data;
};

export const fetchNames = async () => {
  const API_CALL_URL = API_URL+'/inventory/objects/names';
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://'+API_CALL_URL,options);
  const data = await response.json();
  return data;
};

export const fetchCategories = async () => {
  const API_CALL_URL = API_URL+'/inventory/objects/categories';
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://'+API_CALL_URL,options);
  const data = await response.json();
  return data;
};

export const fetchSubcategories = async () => {
  const API_CALL_URL = API_URL+'/inventory/objects/subcategories';
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://'+API_CALL_URL,options);
  const data = await response.json();
  return data;
};

export const fetchLocationPlace = async () => {
  const API_CALL_URL = API_URL+'/inventory/objects/location_place';
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'GET',
    headers: headers
  };
  const response = await fetch('https://'+API_CALL_URL,options);
  const data = await response.json();
  return data;
};

export const createTask = async (taskData) => {
  const API_CALL_URL = API_URL+'/task';
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'POST',
    headers: headers,
    body: JSON.stringify(taskData)
  };
  const response = await fetch('https://'+API_CALL_URL,options);
  const data = await response.json();
  return data;
};

export const updateTask = async (taskID,taskData) => {
  const API_CALL_URL = API_URL+'/task/'+taskID;
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(taskData)
  };
  const response = await fetch('https://'+API_CALL_URL,options);
  const data = await response.json();
  return data;
};

export const deleteTask = async (taskID) => {
  const API_CALL_URL = API_URL+'/task/'+taskID;
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'DELETE',
    headers: headers,
  };
  const response = await fetch('https://'+API_CALL_URL,options);
  const data = await response.json();
  return data;
};

export const uploadFile = async (fileData) => {
  const CLOUD_CALL_URL = CLOUD_URL+'/file';
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'POST',
    headers: headers,
    body: fileData
  };
  const response = await fetch('https://'+CLOUD_CALL_URL,options);
  const data = await response.json();
  return data;
};

export const deleteFile = async (fileURL) => {
  const CLOUD_CALL_URL = CLOUD_URL+'/file/'+fileURL;
  const passport = localStorage.getItem("passport_photos");
  let headers = new Headers();
  headers.append('Authorization', 'Bearer '+passport);
  const options = { 
    method: 'DELETE',
    headers: headers
  };
  const response = await fetch('https://'+CLOUD_CALL_URL,options);
  const data = await response.json();
  return data;
};