import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Masonry from 'react-masonry-css';
import PageHeader from "../components/PageHeader";

function AlbumView({handleToggleMenu, gallery, albums}) {

    let { albumHandle } = useParams();

    const album = albums.filter((album) => (album.handle === albumHandle)).shift();
	console.log(album);

	const [albumTitle, setAlbumTitle] = useState();
	const [albumSubtitle, setAlbumSubtitle] = useState();
	const [albumPhotos, setAlbumPhotos] = useState([]);
    useEffect(() => {
		if(album !== undefined) {
			setAlbumTitle(album.title);
			setAlbumSubtitle(album.subtitle);
			setAlbumPhotos(album.photos);
			console.log(album);
		}
		
	},[album]);
    return (
        <div className='App'>
			<PageHeader handleToggleMenu={handleToggleMenu} />
			<section className="App-section">
				<div className='App-section-header'> 
					<h2 className='text-center'>{albumTitle}<br/><small style={{fontSize:24,fontWeight:"300"}}>{albumSubtitle}</small></h2>
				</div>
				<div className='list-photo mt-4'>
					<Masonry
					breakpointCols={4}
					className="my-masonry-grid"
					columnClassName="my-masonry-grid_column">
						{albumPhotos.map((photo) => (
							<div key={photo.id} className="item-photo">
								<img src={"https://"+photo.photo} alt="album pict" style={{width:'100%'}}/>
								{/* <p>{album.title}</p> */}
							</div>
						))}
					</Masonry>
				</div>
					
			</section>
		</div>
    )
}

export default AlbumView;