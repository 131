import React, { useState, useEffect, useLayoutEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Color from 'color';
import Cookies from 'universal-cookie';

import { askPassport, checkApplication, fetchGallery } from './libs/call';
import { setColors } from './libs/utils';

import { AppProvider } from './contexts/AppProvider.jsx';
import { ThemeProvider } from './contexts/ThemeProvider.jsx';

import AppWrapper from './components/AppWrapper.jsx';

import GaleriesList from './pages/GaleriesList';
import AlbumView from './pages/AlbumView';

import BasicOffcanvas from './ui/Offcanvas';

import Spinner from 'react-bootstrap/Spinner';

import './i18n';

import "./index.scss";

function App() {



  const [ isLoading, setIsLoading ] = useState(true);


  const [ showedMenu, setShowedMenu ] = useState(false);

  const [ originalThemeColor, setOriginalThemeColor ] = useState("");
  const [ originalAppTitle, setOriginalAppTitle ] = useState("");
  const [ originalAppSubtitle, setOriginalAppSubtitle ] = useState("");

  const [gallery, setGallery] = useState({});
	const [albums, setAlbums] = useState([]);

  useLayoutEffect(() => {
    setColors();
  });

  useEffect(() => {

      askPassport().then(() => {
        checkApplication().then((response) => {
          const application = response.result;
          localStorage.setItem("appID",application.id);

          fetchGallery(application.id).then((response) => {
            setGallery(response.result);
            setOriginalAppTitle(response.result.title);
            setOriginalAppSubtitle(response.result.subtitle);
            setAlbums(response.result.albums);
          });

          const appColor = localStorage.getItem("themeColor") || application.system.color_hex;
          const color = Color(appColor);
          setOriginalThemeColor(appColor);
          const systemColor = color.hex();
          let systemColorRGB = color.rgb();
          systemColorRGB = systemColorRGB.color.join(", ");
          const systemColorDarken1 = color.darken(0.1).hex();
          const systemColorDarken2 = color.darken(0.2).hex();
          const systemColorDarken3 = color.darken(0).hex();
          localStorage.setItem("systemColor", systemColor);
          localStorage.setItem("systemColorRGB", systemColorRGB);
          localStorage.setItem("systemColorDarken1", systemColorDarken1);
          localStorage.setItem("systemColorDarken2", systemColorDarken2);
          localStorage.setItem("systemColorDarken3", systemColorDarken3);
          setColors();
          setIsLoading(false);
        });
      });
    
  },[]);



  if(isLoading) {
    return (
      <div className="App-loader">
        <Spinner animation="border" />
        <p className="mt-2">Chargement ...</p>
      </div>

    )
  }


  const handleToggleMenu = () => {
    setShowedMenu(!showedMenu);
  }

  return (
      <ThemeProvider originalTheme={localStorage.getItem("theme") || 'light'} originalThemeColor={localStorage.getItem("themeColor") || originalThemeColor} originalThemeIconClass={localStorage.getItem("themeIconClass") || "solid"}>
        <AppProvider originalAppTitle={originalAppTitle} originalAppSubtitle={originalAppSubtitle} originalAppID={localStorage.getItem("appID") || ''} originalAppLang={localStorage.getItem("appLang") || 'fr'}>
          <AppWrapper>
            <BrowserRouter>    
              <Routes>
                <Route path="/" element={<GaleriesList handleToggleMenu={handleToggleMenu} gallery={gallery} albums={albums} />} />
                <Route path="/:albumHandle" element={<AlbumView handleToggleMenu={handleToggleMenu} gallery={gallery} albums={albums} />} />
              </Routes>
            </BrowserRouter>
            <BasicOffcanvas show={showedMenu} handleCloseMenu={handleToggleMenu} />
          </AppWrapper>
        </AppProvider>
      </ThemeProvider>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);