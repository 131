
import React, { useContext } from 'react';

import { ThemeContext } from '../contexts/ThemeProvider.jsx';

function Icon({name,className}) {

  const { themeIconClass } = useContext(ThemeContext);

  if(className) {
    return (
        <i className={`fa-${themeIconClass} fa-${name} ${className}`}></i>
      );
  }

  return (
    <i className={`fa-${themeIconClass} fa-${name}`}></i>
  );
  
}

export default Icon;


