import React, { useContext } from 'react';
import { Link } from "react-router-dom";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars } from '@fortawesome/free-solid-svg-icons';
import Icon from '../ui/Icon.jsx';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppProvider.jsx';

function PageHeader({handleToggleMenu, isHome}) {

    const { t, i18n } = useTranslation();

    const { appTitle, appSubtitle } = useContext(AppContext);

    if(isHome) {
        return (
            <>
                <header className="App-header">
                    <h1 style={{lineHeight:"35px"}} className="mb-0">{appTitle}<br/><small style={{fontSize:24,fontWeight:"300"}}>{appSubtitle}</small></h1>
                </header>
                <nav className='hamburger'>
                    <span onClick={handleToggleMenu}><Icon name="bars" /></span>
                </nav>
            </>
        )
    }

    return (
        <>
            <header className="App-header">
                <h1 style={{lineHeight:"35px"}} className="mb-0"><Link to="/">{appTitle}<br/><small style={{fontSize:24,fontWeight:"300"}}>{appSubtitle}</small></Link></h1>
            </header>
            <nav className='hamburger'>
                <span onClick={handleToggleMenu}><Icon name="bars" /></span>
            </nav>
        </>
    )

}

export default PageHeader;