import { useState, createContext } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ originalTheme, originalThemeColor, originalThemeIconClass, children }) => {


    const [theme, setTheme] = useState(originalTheme);
    const [themeColor, setThemeColor] = useState(originalThemeColor);
    const [themeIconClass, setThemeIconClass] = useState(originalThemeIconClass);

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light')
    }
 
    return (
        <ThemeContext.Provider value={{ theme, toggleTheme, themeColor, setThemeColor, themeIconClass, setThemeIconClass }}>
            {children}
        </ThemeContext.Provider>
    )
}