import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';



import PageHeader from '../components/PageHeader.jsx';

function GaleriesList({handleToggleMenu, gallery, albums}) {

	const { t } = useTranslation();

	


	return (
		<div className='App'>
			<PageHeader isHome handleToggleMenu={handleToggleMenu} />
			<section className="App-section">
				<div className='App-section-header'> 
					<h2 className='text-center'>Liste des albums</h2>
				</div>
				<div className='list-album mt-4'>
					<Masonry
					breakpointCols={4}
					className="my-masonry-grid"
					columnClassName="my-masonry-grid_column">
						{albums.map((album) => (
							<div key={album.handle} className="item-album">
								<Link to={'/'+album.handle}><img src={"https://"+album.photos[0].photo} alt="album cover" style={{width:'100%'}}/></Link>
								<p className='pt-2'><Link to={'/'+album.handle} className="text-theme">{album.title}<br/><small>{album.subtitle}</small></Link></p>
							</div>
						))}
					</Masonry>
				</div>
			</section>
		</div>
	);
}

export default GaleriesList;